import React, { useState } from "react";
import * as XLSX from "xlsx";
import "./App.css";
import Harita from "./Harita";


function App() {
  const [firmaAdi, setFirmaAdi] = useState("");
  const [adresBilgisi, setAdresBilgisi] = useState("");
  const [yolBilgisi, setYolBilgisi] = useState("");
  const [jsonVeri, setJsonVeri] = useState([]);
  const [aramaFirmaAdi, setAramaFirmaAdi] = useState("");
  const [bulunanAdres, setBulunanAdres] = useState("");

  const handleEkle = () => {
    const yeniVeri = {
      firmaAdi: firmaAdi,
      adresBilgisi: adresBilgisi,
      yolBilgisi: yolBilgisi,
    };
    console.log("Yeni eklenen veri:", yeniVeri); 
    setJsonVeri([...jsonVeri, yeniVeri]);
    setFirmaAdi("");
    setAdresBilgisi("");
    setYolBilgisi("");
  };

  const handleExcelKaydet = async () => {
    const dosyaAdi = "veriler.xlsx";
    let existingData = [];
    let fileHandle;

    try {
      [fileHandle] = await window.showOpenFilePicker({
        types: [
          {
            description: "Excel Files",
            accept: {
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                [".xlsx"],
            },
          },
        ],
      });
      const file = await fileHandle.getFile();
      const arrayBuffer = await file.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      existingData = XLSX.utils.sheet_to_json(worksheet);
    } catch (error) {
      console.error("Dosya okunamadı veya mevcut değil:", error);
    }

    const updatedData = [...existingData, ...jsonVeri];
    const ws = XLSX.utils.json_to_sheet(updatedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Veriler");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    try {
      const writable = await fileHandle.createWritable();
      await writable.write(data);
      await writable.close();
      alert("Dosya başarıyla güncellendi!");
    } catch (error) {
      console.error("Dosya kaydedilemedi:", error);
    }

    setJsonVeri([]);
  };

  const handleArama = async () => {
    console.log("Arama yapılan firma adı:", aramaFirmaAdi);

    let existingData = [];
    try {
      const [fileHandle] = await window.showOpenFilePicker({
        types: [
          {
            description: "Excel Files",
            accept: {
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                [".xlsx"],
            },
          },
        ],
      });
      const file = await fileHandle.getFile();
      const arrayBuffer = await file.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      existingData = XLSX.utils.sheet_to_json(worksheet);
      console.log("Excel'den alınan veriler:", existingData); 

      const filteredFirms = existingData.filter((item) =>
        item.firmaAdi.toLowerCase().includes(aramaFirmaAdi.toLowerCase().trim())
      );

      if (filteredFirms.length > 0) {
        setBulunanAdres(filteredFirms); 
      } else {
        alert("İlgili firma bulunamadı");
      }
    } catch (error) {
      console.error("Dosya okunamadı veya mevcut değil:", error);
      alert("Dosya okunamadı veya mevcut değil");
    }
  };

  return (
    <div>
      <div className="flex flex-col items-center gap-5 mt-10">
        <div className="flex flex-col">
          <div>Firma Adı</div>
          <input
            className="border-2 p-1 rounded-md"
            placeholder="Firma Adı"
            value={firmaAdi}
            onChange={(e) => setFirmaAdi(e.target.value)}
          />
        </div>
        <div className="flex flex-col">
          <div>Adres Bilgisi</div>
          <input
            className="border-2 p-1 rounded-md"
            placeholder="Adres Bilgisi"
            value={adresBilgisi}
            onChange={(e) => setAdresBilgisi(e.target.value)}
          />
        </div>
        <div className="flex flex-col">
          <div>Yol Bilgisi</div>
          <input
            className="border-2 p-1 rounded-md"
            placeholder="Yol Bilgisi"
            value={yolBilgisi}
            onChange={(e) => setYolBilgisi(e.target.value)}
          />
        </div>
        <div
          className="border-2 px-20 p-2 rounded-md cursor-pointer"
          onClick={handleEkle}
        >
          Ekle
        </div>
        <div
          className="border-2 px-20 p-2 rounded-md cursor-pointer mt-5"
          onClick={handleExcelKaydet}
        >
          Excel'e Kaydet
        </div>
        <div className="flex flex-col mt-10">
          <div>Firma Adı ile Ara</div>
          <input
            className="border-2 p-1 rounded-md"
            placeholder="Firma Adı"
            value={aramaFirmaAdi}
            onChange={(e) => setAramaFirmaAdi(e.target.value)}
          />
          <div
            className="border-2 px-20 p-2 rounded-md cursor-pointer mt-5"
            onClick={handleArama}
          >
            Ara
          </div>
        </div>
        {bulunanAdres.length > 0 && (
    <div>
        <h2>Bulunan Firmalar:</h2>
        {bulunanAdres.map((firma, index) => (
            <div key={index} className="mt-5">
                <p>{firma.firmaAdi} - {firma.yolBilgisi}</p>
                <Harita firmaAdi={firma.firmaAdi} adresBilgisi={firma.adresBilgisi} yolBilgisi={firma.yolBilgisi} />
            </div>
        ))}
    </div>
)}

      </div>
      
      <pre>{JSON.stringify(jsonVeri, null, 2)}</pre>
    </div>
  );
}

export default App;
